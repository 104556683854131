import {
  useAPI,
  useAPIMutation,
  useCachedAPIMutation,
  apiClient,
} from "lib/api"

import useToaster from "helpers/toaster"
import {
  bulkElementUpdate,
  createItemDownload,
  createItemsFromDocuments,
  deleteItem,
  getItem,
  getItemPageSignatures,
  updateItem,
  updateTransactionItems,
} from "helpers/api"
import { reduceIntoKeyByValue } from "helpers/array"
import { removeUndefinedProperties } from "helpers/object"
import { useTransactionIdParam } from "helpers/params"

import buildPage from "app/api/item-detail/build-page"

import {
  useTransactionDocuments,
  useTransactionAssignments,
  useTransactionItems,
  useTransactionSignatories,
  useTransactionSignatureBlockTemplates,
} from "features/transaction/id/api"
import {
  type APIItem,
  decode as decodeItem,
  encodeItemUpdate,
  UpdatableItem,
  ItemDownloadKindType,
  Item,
} from "models/Item"
import { APIAssignment, type Assignment } from "models/Assignment"
import {
  DetectPage,
  Page,
  encode as encodePageToAPI,
  decode as decodePageFromAPI,
} from "models/Pages"
import { Signatory } from "models/Signatory"
import { MutableAttachment } from "models/Attachment"
import { UIItemPage } from "models/UIItem"
import { SignatoryAssignment } from "models/SignatoryAssignment"
import { APITransactionElement } from "models/TransactionElement"

function getCachedPages(itemId: string) {
  let itemQuery = apiClient.getQueryData<APIItem>(["items", itemId])

  if (!itemQuery) return []
  return itemQuery.pages?.map(decodePageFromAPI)
}

function selectItemAssignments(itemId: string) {
  return (assignments: Assignment[]) =>
    assignments.filter((a) => a.itemId === itemId)
}

export function useItemAssignments(transactionId: string, itemId: string) {
  let result = useTransactionAssignments(transactionId)
  result.data = result.data
    ? selectItemAssignments(itemId)(result.data)
    : undefined

  return result
}

export function useItem(itemId: string, options = {}) {
  const { data, error } = useAPI(["items", itemId], () => getItem(itemId), {
    select: (item) => (item ? decodeItem(item) : undefined),
    ...options,
  })

  return { item: data, error }
}

export function getItemQueryData(id: string) {
  let apiItem = apiClient.getQueryData<APIItem>(["items", id])

  if (apiItem) {
    return decodeItem(apiItem)
  }
}

export function getItemPage(itemId: string, pageId: string): Page | null {
  let item = getItemQueryData(itemId)

  if (!item) return null

  return item.pages.find((p) => p.id === pageId) || null
}

export function getItemInstaPage(itemId: string, pageId: string) {
  let page = getItemPage(itemId, pageId)

  if (!page || page.type !== "instapagev2") return null

  return page
}

export type PageAssignment = Assignment & {
  authRep:
    | (Signatory & {
        assignmentSignatoryId: string
      })
    | null
  signatory: Signatory | undefined
}

export function useItemDetail(
  transactionId: string,
  itemId: string,
  options = {}
) {
  let itemQuery = useAPI(["items", itemId], () => getItem(itemId), {
    select: (item) => (item ? decodeItem(item) : undefined),
    initialData: () =>
      apiClient
        .getQueryData<APIItem[]>(["transactions", transactionId, "items"])
        ?.find((item) => item.uuid === itemId),
    ...options,
  })

  let itemsQuery = useTransactionItems(transactionId)
  let documentsQuery = useTransactionDocuments(transactionId)
  let assignmentsQuery = useTransactionAssignments(transactionId)
  let signatoriesQuery = useTransactionSignatories(transactionId)
  let signatureBlockTemplatesQuery =
    useTransactionSignatureBlockTemplates(transactionId)

  let queries = [
    assignmentsQuery,
    documentsQuery,
    itemQuery,
    itemsQuery,
    signatoriesQuery,
    signatureBlockTemplatesQuery,
  ]

  let itemsById = reduceIntoKeyByValue(itemsQuery.data || [])
  let documentsById = reduceIntoKeyByValue(documentsQuery.data || [])
  let signatoriesById = reduceIntoKeyByValue(signatoriesQuery.data || [])
  let assignmentsById: Record<string, SignatoryAssignment> =
    reduceIntoKeyByValue(
      selectItemAssignments(itemId)(assignmentsQuery.data || []).map(
        (a): SignatoryAssignment => {
          let maybeAuthRep:
            | (Signatory & {
                assignmentSignatoryId?: string
              })
            | undefined = signatoriesById[a.authRep || ""]

          if (maybeAuthRep) {
            maybeAuthRep.assignmentSignatoryId = String(a.signatory.id || "")
          }

          return {
            ...a,
            authRep: maybeAuthRep,
            signatory: signatoriesById[a.signatory.id],
          }
        }
      )
    )

  let isLoadingInBackground = queries.some((q) => q.isFetching)

  return {
    error: itemQuery.error,
    refetch: itemQuery.refetch,
    data: itemQuery.data
      ? {
          ...itemQuery.data,
          pages: itemQuery.data.pages.map((page) =>
            buildPage({
              page,
              itemsById,
              documentsById,
              assignmentsById,
            })
          ),
        }
      : undefined,
    isLoadingInBackground,
  }
}

export function useDeleteItem(transactionId: string, options = {}) {
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "items"]
  let elementQueryKey = ["transactions", transactionId, "transactionElements"]

  let result = useAPIMutation((id: string) => deleteItem(id), {
    onMutate: async (id) => {
      await apiClient.cancelQueries(queryKey)

      let prevItems = apiClient.getQueryData(queryKey)

      apiClient.setQueryData(
        queryKey,
        (items: APIItem[] | undefined) =>
          items?.filter((item) => item.uuid !== id) || []
      )

      return { prevItems }
    },
    onError: (_, __, context) => {
      failure("There was a problem attempting to delete the document")

      if (context) {
        apiClient.setQueryData(queryKey, context.prevItems)
      }
    },
    onSettled: () =>
      Promise.all([
        apiClient.refetchQueries(queryKey),
        apiClient.refetchQueries(elementQueryKey),
        // Refetch assignments as the cached assignments may include assignments
        // from the just deleted item
        apiClient.refetchQueries([
          "transactions",
          transactionId,
          "assignments",
        ]),
      ]),
    ...options,
  })

  return { ...result, deleteItem: result.mutateAsync }
}

export function useReorderItems(transactionId: string, options = {}) {
  let { failure } = useToaster()
  let queryKey = ["transactions", transactionId, "items"]
  let elementQueryKey = ["transactions", transactionId, "transactionElements"]

  const call = (
    itemsList: string[],
    idsToToggleSupplemental?: string[] | undefined,
    isSupplemental?: boolean
  ) => {
    return updateTransactionItems(transactionId, itemsList)
      .then(() => {
        if (idsToToggleSupplemental && idsToToggleSupplemental.length > 0) {
          idsToToggleSupplemental.forEach((idToToggleSupplemental) => {
            updateItem(
              idToToggleSupplemental,
              encodeItemUpdate({
                id: idToToggleSupplemental,
                isSupplemental: !isSupplemental,
              })
            )
          })
        }
      })
      .then(() => {
        let elements =
          apiClient.getQueryData<APITransactionElement[]>(elementQueryKey) || []
        let itemsByElement = itemsList
          .map(
            (uuid) =>
              elements.find((element) => element.item === uuid) || { uuid }
          )
          .filter(Boolean)

        let elementsList = itemsByElement.map((element) => element?.uuid)

        if (elementsList && elementsList.length > 0) {
          // Updates the elements to be in same order as items
          return bulkElementUpdate({
            ids: elementsList,
            parentElement: null,
            order: 0,
          })
        }
      })
  }

  let result = useAPIMutation(
    (data: {
      itemsList: string[]
      idsToToggleSupplemental?: string[] | undefined
      isSupplemental?: boolean
    }) =>
      call(data.itemsList, data.idsToToggleSupplemental, data.isSupplemental),
    {
      onMutate: async (data: {
        itemsList: string[]
        idsToToggleSupplemental?: string[] | undefined
        isSupplemental?: boolean
      }) => {
        await apiClient.cancelQueries(queryKey)

        let prevItems = apiClient.getQueryData<APIItem[]>(queryKey)
        let prevItemsById = reduceIntoKeyByValue(prevItems || [], "uuid")

        if (
          data.idsToToggleSupplemental &&
          data.idsToToggleSupplemental.length > 0 &&
          prevItems
        ) {
          const idsToUpdate = prevItems.filter(
            (item) => data.idsToToggleSupplemental?.includes(item.uuid)
          )

          idsToUpdate.forEach((item) => {
            prevItemsById[item.uuid] = {
              ...item,
              is_supplemental: !data.isSupplemental,
            }
          })
        }

        apiClient.setQueryData(queryKey, () =>
          data.itemsList.map((uuid) => prevItemsById && prevItemsById[uuid])
        )

        return { prevItems }
      },
      onSettled: () => {
        apiClient.refetchQueries(elementQueryKey)
      },
      onError: (_, __, context) => {
        failure("There was a problem attempting to move the document")

        if (context?.prevItems) {
          apiClient.setQueryData(queryKey, context.prevItems)
        }
      },
      ...options,
    }
  )

  return { ...result, reorderItems: result.mutateAsync }
}

export function useUpdateItem(itemId: string) {
  async function saveCachedItem() {
    let item = apiClient.getQueryData<APIItem>(["items", itemId])

    if (item) {
      await updateItem(itemId, item)
      apiClient.refetchQueries(["transactions", item.transaction, "items"])
    }
  }

  let result = useCachedAPIMutation(["items", itemId], () => saveCachedItem(), {
    cacheUpdaterFn: (fields: UpdatableItem, item: APIItem) => ({
      ...item,
      ...removeUndefinedProperties(encodeItemUpdate(fields)),
    }),
  })

  return { ...result, updateItem: result.mutate }
}

export function useUpdateItemPage(
  itemId: string,
  options: {
    postSuccess?: (data: APIItem) => void
  } = {}
) {
  let result = useCachedAPIMutation(
    ["items", itemId],
    () => saveCachedItemPages(itemId),
    {
      ...options,
      cacheUpdaterFn: (pageData, item: APIItem) => {
        return {
          ...item,
          pages: item.pages.map((page, pageIdx: number) =>
            page.uuid === pageData.pageId
              ? pageData.data
                ? encodePageToAPI(pageData.data)
                : encodePageToAPI(
                    pageData.apply(decodePageFromAPI(page, pageIdx))
                  )
              : page
          ),
        }
      },
    }
  )

  return { ...result, updateItemPage: result.mutate }
}

export function useUpdateItemPages(itemId: string, options = {}) {
  let result = useCachedAPIMutation(
    ["items", itemId],
    () => saveCachedItemPages(itemId),
    {
      ...options,
      cacheUpdaterFn: (pages: Page[], item: APIItem) => {
        return {
          ...item,
          pages: pages.map(encodePageToAPI),
        }
      },
    }
  )

  return {
    ...result,
    updateItemPages: result.mutate,
    updateItemPagesAsync: result.mutateAsync,
  }
}

export function useReplacePages(
  itemId: string,
  transactionId: string,
  options = {}
) {
  let { updateItemPages } = useUpdateItemPages(itemId, options)

  async function replacePages(pages: Page[]) {
    // NOTE need to refetch all documents before applying the new pages
    // or else the page will reference a document / img that doesn't exist
    // in the cache yet
    await apiClient.refetchQueries(["transactions", transactionId, "documents"])
    updateItemPages(pages)
    await refetchCachedItemsAndDocuments(transactionId)
  }

  return { replacePages }
}

export function useInsertPages(
  itemId: string,
  transactionId: string,
  options = {}
) {
  let { updateItemPagesAsync } = useUpdateItemPages(itemId, options)

  async function insertPages(pages: Page[]) {
    await updateItemPagesAsync(pages)
    await refetchCachedItemsAndDocuments(transactionId)
  }

  return { insertPages }
}

export function useUpdateItemVersion(
  itemId: string,
  transactionId: string,
  options = {}
) {
  let { updateItemPagesAsync } = useUpdateItemPages(itemId, options)

  async function updateItemVersion({
    pages,
    name,
  }: {
    pages: Page[]
    name: string
  }) {
    await updateItemPagesAsync(pages)
    if (name) {
      await updateItem(itemId, { name, uuid: itemId })
    }
    await refetchCachedItemsAndDocuments(transactionId)
  }

  return { updateItemVersion }
}

async function saveCachedItemPages(itemId: string) {
  let item = apiClient.getQueryData<APIItem>(["items", itemId])

  if (item) {
    return await updateItem(itemId, {
      uuid: itemId,
      pages: item.pages.map((page) => page),
    })
  }
}

function useDetectSignatures(itemId: string) {
  let { updateItemPage } = useUpdateItemPage(itemId)

  async function detectSignatures(page: DetectPage) {
    let assignments: APIAssignment[] = []
    try {
      assignments = (await getItemPageSignatures(page)) || []
    } catch (e) {
      if (e instanceof Error && "status" in e && e.status !== 404) {
        console.error("Error detectSignatures", e)
      }
    }

    if (assignments.length > 0) {
      await apiClient.invalidateQueries([
        "transactions",
        page.transactionId,
        "assignments",
      ])
    }

    return updateItemPage({
      pageId: page.id,
      apply: (p: Page) => ({
        ...p,
        id: page.id,
        forSigning: true,
        assignments: assignments.map((a) => a.uuid),
      }),
    })
  }

  return { detectSignatures }
}

export function useDeletePage(itemId: string) {
  let { updateItemPages } = useUpdateItemPages(itemId)

  async function deletePage(pageId: string) {
    try {
      let pages = getCachedPages(itemId)
      let page = pages.find((p) => p.id === pageId)

      updateItemPages(pages.filter((p) => p.id !== pageId))

      return { page }
    } catch (error) {
      return { error }
    }
  }

  return { deletePage }
}

function useDeletePageAssignments(itemId: string) {
  let { updateItemPage } = useUpdateItemPage(itemId)

  async function deletePageAssignments(page: UIItemPage) {
    updateItemPage({
      pageId: page.id,
      data: {
        ...page,
        forSigning: false,
        assignments: [],
      },
    })
  }

  return { deletePageAssignments }
}

export async function updateItemPage(
  itemId: string,
  pageId: string,
  updateFn = (p: Page) => p
) {
  try {
    if (!itemId || !pageId) return
    let itemQuery = apiClient.getQueryData<APIItem>(["items", itemId])
    let item = itemQuery && decodeItem(itemQuery)
    let pages = item?.pages.map((p) => {
      if (p.id === pageId) {
        return updateFn(p)
      }
      return p
    })

    let updatedItem = await updateItem(itemId, {
      uuid: itemId,
      pages: pages?.map((page) => encodePageToAPI(page)),
    })
    apiClient.setQueryData(["items", itemId], () => updatedItem)
  } finally {
    console.log("TRIGGER ALL CACHE EXP")
  }
}

export function useToggleSignaturePage(itemId: string) {
  let transactionId = useTransactionIdParam()
  let { detectSignatures } = useDetectSignatures(itemId)
  let { deletePageAssignments } = useDeletePageAssignments(itemId)

  async function toggleSignaturePage(page: UIItemPage) {
    let pageData = { ...page, itemId, transactionId }

    return !page.isSignaturePage
      ? detectSignatures(pageData)
      : deletePageAssignments(pageData)
  }

  return { toggleSignaturePage }
}

async function refetchCachedItemsAndDocuments(transactionId: string) {
  await Promise.all([
    apiClient.refetchQueries(["transactions", transactionId, "items"], {
      exact: true,
    }),
    apiClient.refetchQueries(["transactions", transactionId, "documents"], {
      exact: true,
    }),
  ])
}

export function useCreateOrUpdateAttachment(
  itemId: string,
  transactionId: string,
  pageId: string,
  { onError } = { onError() {} }
) {
  let { updateItemPage } = useUpdateItemPage(itemId)

  async function call(attachment: MutableAttachment) {
    let itemDocs: { document_id: string; item_id: string }[] = []
    let newItems: Item[] = []
    let newDocumentIds = attachment.items
      .filter((i) => i.type === "UPLOAD")
      .map(({ id }) => id)

    if (newDocumentIds.length > 0) {
      itemDocs = (await createItemsFromDocuments(newDocumentIds)) || []
      await refetchCachedItemsAndDocuments(transactionId)

      newItems =
        apiClient
          .getQueryData<APIItem[]>(["transactions", transactionId, "items"])
          ?.filter((item) =>
            itemDocs.map(({ item_id }) => item_id).includes(item.uuid)
          )
          .map(decodeItem) || []

      // Set the new items as supplemental and not for signing
      newItems.forEach((item) =>
        updateItem(item.id, {
          uuid: item.id,
          is_supplemental: true,
          for_signing: false,
        })
      )
    }

    let persistedItems: Item[] = attachment.items
      .map((attachmentItem) => {
        if (attachmentItem.type === "UPLOAD") {
          let ids = itemDocs.find(
            (itemDoc) => itemDoc.document_id === attachmentItem.id
          )

          return newItems.find((newItem) => newItem.id === ids?.item_id)
        }
        return attachmentItem
      })
      .filter((item): item is Item => Boolean(item))

    updateItemPage({
      pageId,
      apply: (p: MutableAttachment) => ({
        ...p,
        name: attachment.name,
        label: attachment.label,
        items: persistedItems.map(({ id }) => id).filter(Boolean),
      }),
    })

    return persistedItems
  }

  let result = useAPIMutation(
    (attachment: MutableAttachment) => call(attachment),
    { onError }
  )

  return { ...result, createOrUpdateAttachment: result.mutateAsync }
}

export function useItemDownload(
  itemId: string,
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  return useAPIMutation(
    (kind: ItemDownloadKindType) => createItemDownload(itemId, kind),
    {
      onSuccess: options.onSuccess,
      onError: options.onError,
    }
  )
}
