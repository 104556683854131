import styled from "@emotion/styled"
import { Colors } from "@blueprintjs/core"
import Link from "next/link"

export const DropContainer = styled.div<{ isOver?: boolean }>`
  counter-reset: closing-binder-counter;
  border-radius: 0.25rem;
  ${(props) =>
    props.isOver &&
    `background: rgba(189, 107, 189, 0.3);`}; /* Colors.VIOLET4 at 70% transparency*/
`

export const InsertBar = styled.div<{ depth?: number }>`
  border: 1px solid ${Colors.VIOLET4};
  top: -1px;
  pointer-events: none;
  position: absolute;
  width: calc(100% + 0.5rem);
  margin-left: -0.25rem;
  z-index: 1;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: -0.25rem;
    left: -0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${Colors.VIOLET4};
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: ${Colors.VIOLET4};
  }
`

export const SupplementalDocumentsList = styled.div<{ isOver?: boolean }>`
  border-radius: 0.25rem;
  border: 1px solid transparent;

  ${(props) =>
    props.isOver &&
    /* Colors.VIOLET4 at 70% transparency */
    `background: rgba(189, 107, 189, 0.3); 
    border: 1px solid ${Colors.VIOLET4};
    `}
`

export const renderLink = ({
  name,
  id,
  transactionId,
  isEmbedded,
}: {
  name: string
  id: string
  transactionId: string
  isEmbedded: boolean
}) => {
  return (
    <Link
      href="/transaction/[id]/item/[itemId]"
      as={`/transaction/${transactionId}/item/${id}`}
      data-tracking-action-name={`sa-evt-dashboard-item-name=${id}`}
      className="hover:no-underline"
      onClick={(evt) => {
        if (isEmbedded) {
          evt.stopPropagation()
          evt.preventDefault()
        }

        if (evt.metaKey || evt.ctrlKey || evt.shiftKey) {
          evt.preventDefault()
        }
      }}
    >
      {name}
    </Link>
  )
}
